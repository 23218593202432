import React from "react";

import Layout from "../components/layout";
import Hero from "../components/hero";
import SEO from "../components/seo";
import Showcase from "../components/showcase";
import About from "../components/about";
import Contact from "../components/contact";
import Footer from "../components/footer";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Boost sales and grow your business"
        keywords={[`shopify`, `e-commerce`, `shop`, `sales`]}
      />
      <Hero />
      <Showcase />
      <About />
      <Contact />
    </Layout>
  );
}

export default IndexPage;
